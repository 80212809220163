import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../Button";

const HeroSection = ({}) => {
  return (
    <header className="relative flex justify-between mt-12 lg:mt-20 herosectionbgcolor lg:flex flex-col lg:flex-row lg:items-center h-[757px] lg:h-[624px]">
      <div className="w-full pt-[53px] lg:pt-0 pl-[5%] lg:pl-[10%] relative lg:z-10">
        <p className="font-extrabold fontFamily2 text-5xl sm:text-[52px] text-white sm:leading-[63px] mb-6">
          Recover, Relax, Recharge.
        </p>
        <p className="text-gray_50 text-xl leading-[30px] sm:text-3xl sm:leading-10 font-normal fontFamily2 w-full sm:w-[550px] mb-6 ">
          From whole body cryotherapy to custom massage, we help DC recover
          fast.
        </p>
        <a href="https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/services?lid=429303&eid=1256270&oiid=sv%3A15756249&pId=400656">
        <Button
          
          texts={"FREE CONSULTATION"}
          customClass={
            "w-[231px] bg-primary_green text-white fontFamily2 rounded-sm h-12 font-semibold text-base tracking-[0.03em]  transition hover:bg-green_400 hover:text-purple"
          }
        />
        </a>
      </div>
      <StaticImage
        src="../../static/images/hero-section-bg.png"
        alt="bg-img"
        placeholder="none"
        className="!relative  h-[335px] lg:!absolute lg:w-auto lg:right-0 lg:h-[624px]"
      />
    </header>
  );
};

export default HeroSection;
