import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

const BlogSection = () => {
  const { featuredBlogs } = useStaticQuery(graphql`
    query GetFeaturedBlogs {
      featuredBlogs: allSanityBlogs(
        filter: { featured: { eq: true } }
        limit: 3
      ) {
        nodes {
          title
          slug {
            current
          }
          featuredImage {
            asset {
              gatsbyImageData
              url
            }
          }
          author {
            name
          }
        }
      }
    }
  `);

  const blogs = featuredBlogs?.nodes;
  return (
    <section className="w-[90%] lg:w-4/5 mx-auto mb-[84px] lg:mb-[142px] ">
      <div className="flex justify-center mb-12">
        <h2 className="fontFamily2 font-bold text-4xl leading-[44px] text-gray_900">
          HighKey Health & Wellness Blog
        </h2>
      </div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {blogs?.map(
          (
            { title, author: { name }, featuredImage, slug: { current } },
            index
          ) => {
            return (
              <Link to={`/${current}`} key={current}>
                <div className="w-full ">
                  <GatsbyImage
                    image={featuredImage?.asset?.gatsbyImageData}
                    loading="lazy"
                    className="w-full  lg:h-[216px] 2xl:h-[276px]"
                    alt="card-img"
                  />
                  <h6 className="mt-6 font-semibold text-sm leading-[18px] text-gray_300 fontFamily2 mb-2 uppercase">
                    {name}
                  </h6>
                  <p className="fontFamily2 font-normal text-xl leading-30px text-gray_900">
                    {title}
                  </p>
                </div>
              </Link>
            );
          }
        )}
      </div>
    </section>
  );
};

export default BlogSection;
