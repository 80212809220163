import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from 'gatsby';

const GiftCardsSection = () => {
  return (
    <section className="w-full lg:w-[90%] bg-black_typography mt-[230px] md:mt-[240px] mb-[170px] lg:mb-[150px] h-[643px] lg:h-[536px] flex items-end lg:items-center relative">
      <div className="w-full flex justify-end">
        <StaticImage
        placeholder="none"
          src="../../static/svgs/decoration-symbol.svg"
          alt="bg-img"
          className="!absolute right-[27px] lg:right-[81px] bottom-[-52px] lg:bottom-[-70px] z-10"
        />
        <StaticImage
        placeholder="none"
          src="../../static/images/gift-section-img.png"
          alt="bg-img"
          class="!absolute left-0 top-[-120px] h-[261.45px] md:top-[-200px] lg:top-[-78px] sm:h-auto w-[341px] lg:h-auto  sm:w-[75%] xl:h-auto  lg:w-2/4 2xl:h-auto 2xl:w-auto"
        />
        <div className="gift_card_section bg-green_400_down_opacity h-[539px] lg:right-[-75px] absolute bottom-[-80px] z-[-1]"></div>
        <div className="w-full pl-[5%] lg:w-[40%] pr-10 lg:mb-0 mb-[45px]">
          <h2 className="text-white text-[35px] leading-[43px] xl:text-4xl xl:leading-[44px] font-bold mb-6 fontFamily2">
            Get To Know Us
          </h2>
          <p className="fontFamily1 font-normal text-base leading-7 text-gray-50 w-full xl:w-[432px] mb-6 ">
            At HighKey Balance, we’re passionate about healthy, alternative
            practices that help you recover because we believe the best journey
            in life is the journey back to yourself. Learn more about our team’s
            mission and philosophy, and tour our advanced facility.
          </p>
              <Link
                to="/wellness-experts"
                className="fontFamily2 relative text-base before:transition-[width]  font-semibold  gradieant_text_button   before:content-'' before:w-[12px] before:h-[3px] before:absolute before:bottom-[-8px]  before:rounded-sm before:left-0 before:bg-white hover:before:w-full  hover:before:bg-primary_green "
              >
                LEARN MORE
            </Link>
        </div>
      </div>
    </section>
  );
};

export default GiftCardsSection;
