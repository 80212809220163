import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const AboutSection = () => {
  return (
    <section className="w-full lg:w-[90%] pl-[5%] lg:ml-[10%] bg-black_typography mt-[200px] md:mt-[240px] mb-[75px] lg:mb-[150px] h-[643px] lg:h-[536px] flex items-end lg:items-center relative">
      <div className="w-full">
        <StaticImage
          src="../../static/svgs/decoration-symbol.svg"
          alt="bg-img"
          placeholder="none"
          className="!absolute left-[27px] lg:left-[52px] top-[102px] lg:top-[-70px] z-10"
        />
        <StaticImage
        placeholder="none"
          src="../../static/images/AboutBlock-img.png"
          alt="bg-img"
          class="!absolute right-0 top-[-120px] h-[261.45px] md:top-[-200px] lg:top-[-78px] sm:h-auto w-[341px] lg:h-auto  sm:w-[75%] xl:h-auto  lg:w-2/4 2xl:h-auto 2xl:w-auto"
        />
        <div className="w-full lg:w-2/4 pr-10 lg:mb-0 mb-[45px]">
          <h2 className="text-white text-[35px] leading-[43px] xl:text-4xl xl:leading-[44px] font-bold mb-6 fontFamily2">
            Restore Before More
          </h2>
          <p className="fontFamily1 font-normal text-base leading-7 text-gray-50 w-full xl:w-[432px] mb-6 ">
            Intense physical activity, like training, playing sports, or
            running, breaks your muscles down. It's rest and recovery that build
            them back up. Developing a good activity and rest cycle as part of
            your fitness routine is as key to your performance as your training
            program.
          </p>
          <Link
            to="/wellness-packages"
            className="fontFamily2 relative text-base before:transition-[width]  font-semibold  gradieant_text_button   before:content-'' before:w-[12px] before:h-[3px] before:absolute before:bottom-[-8px]  before:rounded-sm before:left-0 before:bg-white hover:before:w-full  hover:before:bg-primary_green "
          >
            LEARN MORE
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
