import React from "react";
import CardGrid from "../CardGrid";

const ServicesSection = ({ services }) => {
  return (
    <section className="w-full px-[5%] lg:px-[10%] mb-[82px] lg:mb-[120px]">
      <div className="mb-12">
        <h2 className="fontFamily2 font-bold text-[30px] lg:text-[42px] leading-[51px] text-black_typography mb-4">
          Heighten Your <span className="gradieant_text">Recovery</span>
        </h2>
        <p className="font-normal lg:w-[789px] text-base leading-7 fontFamily1 text-typography_body">
            In a world where we are constantly pulled in different directions, juggling multiple roles and leading busy lives, it's easy to overlook the importance of self-care. Implementing proper rest and recovery is essential to avoid injuries and improve the quality of life. Let us be the pillar where you can implement these crucial routines, guiding you through personalized wellness practices designed just for you.
        </p>
      </div>
      <div className="grid sm:grid-cols-2  sm:gap-x-7 lg:grid-cols-3 gap-x-10 gap-y-12">
        <CardGrid cardType={"secondary"} cardData={services} />
      </div>
    </section>
  );
};

export default ServicesSection;
