import React from "react";
import Card from "../Card";

const CardGrid = ({ cardData, cardType }) => {
  return (
    <>
      {cardData.map((data) => {
        return <Card data={data} type={cardType} key ={data.title} />;
      })}
    </>
  );
};

export default CardGrid;
