import React from "react";
import Button from "../Button";

const CTASection = () => {
  return (
    <section className="w-full px-[3%] md:px-[10%] mt-[69px] lg:mt-[94px]">
      <div className="flex items-center mb-[70px] lg:mb-[108px] flex-col ">
        <header>
          <h1 className="fontFamily2  text-[40px] leading-[48.76px] md:text-[42px] md:leading-[51.2px] font-bold w-full md:w-[700px] text-center text-purple mb-6">
            Washington D.C.'s All-In-One Wellness & Recovery Studio
          </h1>
        </header>
        <article>
          <p className="font-normal text-base mb-6 leading-7 text-fontFamily1 text-typography_body text-center w-full md:w-[680px]">
            You’re tough on your body. We help it heal. HighKey Balance takes a
            holistic approach to your recovery. We help you get back to doing what you love most—whether you’re a professional athlete, weekend warrior, or focused on self-care.
          </p>
        </article>
        <a
          href="https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/all-offer?pId=400656"
        >
          <Button
            texts={"Book Now"}
            customClass={
              "bg-primary_green rounded-sm h-12 w-[165px] text-base text-white font-semibold fontFamily2 tracking-[0.03em] transition hover:bg-green_400 hover:text-purple"
            }
          />
        </a>
      </div>
    </section>
  );
};

export default CTASection;
